import React from 'react'
import { Link } from 'gatsby'
import Social from '../components/Social';
import About from '../components/About'
import SocialSmall from '../components/SocialSmall';
import Footer from '../components/Footer';
import '../assets/main.css'

class Layout extends React.Component {
	render() {
		const { location, children } = this.props
		const rootPath = `${__PATH_PREFIX__}/`
		let header

		if (location.pathname === rootPath) {
			header = (
				<>
					<header
						className='wave-mask'
						style={{
							left: 0,
							top: 0,
							height: '80vh',
							width: '100%',
							zIndex: 10,
							position: 'relative',
							overflow: 'hidden'
						}}
					>
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '80vh',
								background: 'rgba(0, 0, 0, 0.7) none repeat scroll 0% 0%',
								zIndex: 2
							}}
							className="hero-overlay"
						/>
						<img
							id="Hero"
							style={{
								opacity: 0,
								transition: '0.5s',
								objectFit: 'cover',
								width: '100%',
								height: '100%'
							}}
							src="/images/header-image.jpg"
							alt="Marc’s Dev Blog background"
						/>
						<img
							style={{
								width: '360px',
								maxWidth: '80%',
								height: 'auto',
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate(-50%, -50%)',
								margin: 0,
								zIndex: 3
							}}
							src="/images/logo.svg"
							alt="Marc’s Dev Blog"
						/>

						<SocialSmall />
					</header>
					<div className='main-content'>
						<About />
					</div>
				</>
			)
		} else {
			header = (
				<header
					style={{
						background: 'url(/images/header-image.jpg) no-repeat scroll center center',
						backgroundSize: 'cover',
						color: 'white',
						left: 0,
						top: 0,
						height: '25vh',
						minHeight: '200px',
						width: '100%',
						zIndex: 10,
						position: 'relative'
					}}
					className="postHeader"
				>
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '25vh',
							minHeight: '200px',
							background: 'linear-gradient(to bottom, rgba(0,0,0,0.75) 0%,rgba(0,0,0,1) 100%)'
						}}
					>
					</div>
					<Link
						style={{
							boxShadow: 'none',
							textDecoration: 'none',
							color: 'inherit',
						}}
						to={'/'}
					>
						<img
							className="logo"
							style={{
								width: '150px',
								height: 'auto',
								position: 'absolute',
								top: '2em',
								left: '2em',
								margin: 0,
							}}
							src="/images/logo.svg" alt="Marc’s Dev Blog" />
					</Link>
					<SocialSmall />
				</header>
			)
		}

		return (
			<>
				{header}
				{children}
				<div className='trees-mask'>
					<Social />
					<Footer />
				</div>
			</>
		)
	}

	componentDidMount() {

		// Parallax fadeIn
		const parallax = document.getElementById('Hero');
		if (parallax) {
			setTimeout(function () {
				document.getElementById('Hero').style.opacity = 1;
			}, 500);
			setTimeout(function () {
				document.getElementById('Hero').style.transition = "none";
			}, 1000);
		}

	}

}

export default Layout
