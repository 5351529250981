import React, { Component } from 'react';

export default class SocialSmall extends Component {
	render() {
		return (
			<div className="SocialSmall">
				<a href="https://twitter.com/ma_lavigne" target="_blank" rel="noopener noreferrer">
					<img src="/images/twitter-logo.svg" alt="@ma_lavigne" />
				</a>
				<a href="https://github.com/marcandrelavigne" target="_blank" rel="noopener noreferrer">
					<img src="/images/github-logo.svg" alt="marcandrelavigne" />
				</a>
				<a href="https://codepen.io/malavigne" target="_blank" rel="noopener noreferrer">
					<img src="/images/codepen-logo.svg" alt="malavigne" />
				</a>
			</div>
		);
	}
}
