import React, { Component } from 'react';
export default class Footer extends Component {
	render() {
		return (
			<footer
				style={{
					color: 'white',
					left: 0,
					top: 0,
					width: '100%',
					textAlign: 'center',
					padding: '2em'
				}}
				className="footer"
			>
				<img
					style={{
						width: '10rem',
						height: 'auto',
						margin: '2em auto',
						opacity: '0.6'
					}}
					src="/images/alternate-logo.svg" alt="Marc’s Dev Blog" />
				<p
					style={{
						margin: 0,
						textAlign: 'center',
						padding: '1em 0',
					}}
				>
					© {(new Date().getFullYear())} All Rights Reserved - Built with <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby</a> and hosted on <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a> ❤️
				</p>
			</footer>
		);
	}
}
