import React, { Component } from 'react';
import Bio from '../components/Bio'

export default class About extends Component {

	render() {
		return (
			<div
				className="gradient-top"
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
					marginBottom: '5em',
					width: '100%',
				}}
			>
				<div
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						maxWidth: '55rem',
						padding: '6rem 0 2rem',
						lineHeight: 1.5,
						position: 'relative',
						zIndex: 1
					}}
				>
					<Bio />
				</div>
			</div>
		);
	}
}