import React from 'react'

class Bio extends React.Component {
	render() {
		return (
			<div className="bioWrap">
				<h2>Hello there! 👋</h2>
				<p>Welcome on Marc’s Dev Blog! My name is <strong>Marc-Andre Lavigne</strong>. I work as a Full-Stack Developer and I’m located in Sherbrooke, Quebec, Canada. Family father, tech person and lover of the great outdoors, I write (sometimes) about a broader aspect of development and experience sharing.</p>
			</div>
		)
	}
}

export default Bio
