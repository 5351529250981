import React, { Component } from 'react';

export default class Social extends Component {
	render() {
		return (
			<div
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
					padding: '14rem 1.3125rem 0',
					top: 0,
					width: '100%',
					textAlign: 'center'
				}}
				className='socialFooter'
			>
				<h2
					style={{
						maxWidth: '70rem',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: '2.5rem'
					}}
				>
					Let’s keep in touch!
				</h2>
				<div
					style={{
						display: 'flex',
						maxWidth: '70rem',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginBottom: 0,
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					<p>You should follow me on one of those fine platforms:</p>
					<p><a href="https://twitter.com/ma_lavigne" target="_blank" rel="noopener noreferrer">Twitter</a>, <a href="https://github.com/marcandrelavigne" target="_blank" rel="noopener noreferrer">GitHub</a>, <a href="https://codepen.io/malavigne" target="_blank" rel="noopener noreferrer">CodePen</a>.</p>
				</div>
			</div>
		);
	}
}
